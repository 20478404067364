import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import BidsCarousel from "./TruckloadCarousel";
import Tippy from "@tippyjs/react";
import Link from "next/link";
const STORE = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 2,
    text: "TGT",
  },
  {
    id: 3,
    text: "WM",
  },
  {
    id: 4,
    text: "LWS",
  },
  {
    id: 5,
    text: "DG",
  },
  {
    id: 6,
    text: "HD",
  },
  {
    id: 7,
    text: "AMZ",
  },
  {
    id: 8,
    text: "JCP",
  },
  {
    id: 9,
    text: "KL",
  },
];

const Bids = ({ classes = "pt-18 pb-20 md:pt-0 md:pb-8", bgWhite,truckloads }) => {
  const [sortActive, setsortActive] = useState(1);
  const [truckloadFilter, setTruckloadFilter] = useState("all");


  const filteredTruckloads = truckloads.filter((trk) => {
    if (truckloadFilter.toLowerCase() === "all") {
      return trk;
    } else {
      return trk?.store?.toLowerCase() === truckloadFilter.toLowerCase()
    }
  });
  return (
    <section className={classes}>
      {/* <!-- Hot Bids --> */}
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
      )}
      <div className="container">
        <HeadLine
          text="Truckloads"
          // image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png"
          classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
        />
        {/* <Recently_added_dropdown data={sortText} dropdownFor="recently_added"/> */}
        <div>
          {/* dropdown */}
          <div className="dropdown relative my-1 cursor-pointer">
            <Tippy
              animation="fade"
              arrow={false}
              trigger="click"
              interactive="true"
              placement="bottom"
              className="tooltip-container"
              content={
                <div
                  className="dropdown-menu dark:bg-jacarta-800 ml-[2.5rem] md:ml-0 z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-jacarta-500"
                  aria-labelledby="categoriesSort"
                >
                  {/* <span className="font-display text-jacarta-300 block px-5 py-2 text-sm font-semibold">
                    Truckload vendor
                  </span> */}
                  {STORE.map((item) => {
                    const { id, text } = item;
                    return (
                      <button
                        key={id}
                        className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                        onClick={() => {
                          setsortActive(id);

                          setTruckloadFilter(text);
                        }}
                      >
                        {text}
                        {sortActive === id && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="fill-accent mb-[3px] h-4 w-4"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                          </svg>
                        )}
                      </button>
                    );
                  })}

                </div>
              }
            >
              <div className="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white">
                <span className="font-display">By Store</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </svg>
              </div>
            </Tippy>
          </div>
        </div>

        <div className="relative">
          {/* <!-- Slider --> */}

          <BidsCarousel Truckloads={filteredTruckloads} />

          <div className="mt-10 text-center">
            <Link
              href="/explore_truckloads"
            >
              <a className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">See All Truckloads</a>
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- end hot bids --> */}
    </section>
  );
};

export default Bids;
