import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Pagination } from "@mui/material";
import Image from "next/image";
import { MdSearch, MdStar } from "react-icons/md";
import { useSelector } from "react-redux";


//product details modal
const DetailsModal = ({ product }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        className="text-jacarta-700 dark:text-accent"
        type="button"
        data-modal-toggle="defaultModal"
        onClick={() => setShowModal(true)}
      >
        <MdSearch />
      </button>

      <div className={showModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Product details
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 overflow-y-auto">
              <div className="flex flex-row justify-center">
                <Image
                  className="object-cover rounded-[0.625rem]"
                  alt=""
                  src={
                    product.images[0]?.url
                      ? `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${product.images[0]?.url}`
                      : `/images/no_product.png`
                  }
                  height={250}
                  width={250}
                  objectFit="cover"
                />
              </div>
              <div className="p-5">
                <h5 className="mb-2 text-xl font-bold text-accent dark:text-accent">
                  {product.title}
                </h5>
                <div className="flex flex-row justify-around w-full px-5">
                  <div className="mr-1">
                    {(product?.gtinUpc || product?.upc) && (
                      <p className="font-display block text-jacarta-700 dark:text-white">
                        UPC:{" "}
                        <span className="text-accent">
                          {product?.gtinUpc || product?.upc}
                        </span>
                      </p>
                    )}
                    <p className="font-display block text-jacarta-700 dark:text-white">
                      Price:{" "}
                      <span className="text-accent">
                        ${" "}
                        {(
                          Number(product.price) / Number(product.quantity)
                        ).toFixed(2)}
                      </span>
                    </p>

                    <p className="font-display block text-jacarta-700 dark:text-white">
                      Category:{" "}
                      <span className="text-accent">
                        {" "}
                        {product.categories !== null
                          ? product.categories[0]
                          : "---"}
                      </span>
                    </p>

                    {!isNaN(Number(product.rating).toFixed(1)) ? (
                      <p className="font-display block text-jacarta-700 dark:text-white">
                        Rating:{" "}
                        <span className="text-accent">
                          {Number(product.rating).toFixed(1)}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                    <p className="font-display block text-jacarta-700 dark:text-white">
                      QTY:{" "}
                      <span className="text-accent"> {product.quantity}</span>
                    </p>
                  </div>
                  <div>
                    <p className="font-display block text-jacarta-700 dark:text-white">
                      Condition:{" "}
                      <span className="text-accent"> {product.condition}</span>
                    </p>
                    {product.brand && (
                      <p className="font-display block text-jacarta-700 dark:text-white">
                        Brand:{" "}
                        <span className="text-accent"> {product.brand}</span>
                      </p>
                    )}
                    <p className="font-display block text-jacarta-700 dark:text-white">
                      Model:{" "}
                      <span className="text-accent">
                        {" "}
                        {product.modelNumber}
                      </span>
                    </p>
                    <p className="font-display block text-jacarta-700 dark:text-white">
                      Total:{" "}
                      <span className="text-accent">
                        $ {Number(product.price).toFixed(2)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const OfferTab = ({ truckloadItems, manifest_url }) => {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  // const [itemOffset, setItemOffset] = useState(0);
  // const [data, setData] = useState(truckloadItems);
  // const [dropdownShow, setDropdownShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showFullScannedManifest, setShowFullScannedManifest] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user.isLoggedIn) {
      setShowFullScannedManifest(true);
    } else setShowFullScannedManifest(false);
  }, [user.isLoggedIn]);

  const handlePageChange = (event, val) => {
    setPageNumber(val);
  };
  // const timeText = [
  //   {
  //     id: 1,
  //     text: "Last 24 Hours",
  //   },
  //   {
  //     id: 2,
  //     text: "Last 7 days",
  //   },
  //   {
  //     id: 3,
  //     text: "Last 30 days",
  //   },
  // ];

  // const handleFilter = (text) => {
  //   setTimeActiveText(text);
  //   const newCollectionData = truckloadItems.filter((item) => {
  //     if (text === "Last 30 days") {
  //       return item;
  //     }
  //     return item.postDate === text;
  //   });
  //   setData(newCollectionData);
  // };

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + 20;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //   setCurrentItems(data.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(data.length / 20));
  // }, [itemOffset]);
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * 20) % data.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };

  // const downloadHandle = () => {
  //   window.location =
  //     process.env.NEXT_PUBLIC_ASSETS_BASE_URL + "/" + manifest_url;
  // };

  const transformToShopify = () => {
    const rows = [
      [
        "upc",
        "internet_id",
        "modelNumber",
        "title",
        "description",
        "listingURL",
        "wholesale_price",
        "brand",
        "rating",
        "categories",
      ],
    ];

    truckloadItems.forEach((el) => {
      console.log(el);
      const row = [
        el.gtinUpc,
        el.upc,
        el.modelNumber,
        el.title?.replace(/\s+|,/g, "-").toLowerCase(),
        el.description?.replace(/\s+|,/g, "-").toLowerCase(),
        el.listingURL?.replace(/\s+|,/g, "-").toLowerCase(),
        el.wholesale_price,
        el.brand,
        el.rating,
        el.categories?.join(",")?.replace(/\s+|,/g, "-").toLowerCase(),
      ];
      rows.push(row);
    });
    return rows;
  };

  const downloadHandle = () => {
    const data = transformToShopify();
    const csv = data.map((r) => r.join(",")).join("\n");

    const filename = "export.csv";
    const contentType = "text/csv;charset=utf-8";
    const content = encodeURIComponent(csv);
    const a = document.createElement("a");

    a.download = filename;
    a.href = `data:${contentType},${content}`;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      {/* <!-- Offers --> */}
      <div
        className="tab-pane fade show active"
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <div>
          <section className="dark:bg-jacarta-800 relative py-4">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src="/images/gradient_light.jpg"
                alt="gradient"
                className="h-full w-full"
              />
            </picture>
            {/* <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base border border-b-0 px-4 pt-5 pb-2.5"> */}
            {showFullScannedManifest ? (
              <div className="container">
                <button
                  onClick={downloadHandle}
                  className="dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border bg-white px-4 py-3 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                >
                  <svg
                    className="fill-jacarta-700 dark:fill-accent"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17.064,4.656l-2.05-2.035C14.936,2.544,14.831,2.5,14.721,2.5H3.854c-0.229,0-0.417,0.188-0.417,0.417v14.167c0,0.229,0.188,0.417,0.417,0.417h12.917c0.229,0,0.416-0.188,0.416-0.417V4.952C17.188,4.84,17.144,4.733,17.064,4.656M6.354,3.333h7.917V10H6.354V3.333z M16.354,16.667H4.271V3.333h1.25v7.083c0,0.229,0.188,0.417,0.417,0.417h8.75c0.229,0,0.416-0.188,0.416-0.417V3.886l1.25,1.239V16.667z M13.402,4.688v3.958c0,0.229-0.186,0.417-0.417,0.417c-0.229,0-0.417-0.188-0.417-0.417V4.688c0-0.229,0.188-0.417,0.417-0.417C13.217,4.271,13.402,4.458,13.402,4.688"></path>
                  </svg>
                  <span className="text-2xs ml-2 font-medium">
                    Full Scanned Manifest
                  </span>
                </button>
              </div>
            ) : (
              <div className="container">
                 <Link href={"/login"}>
                <button
                  className="dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border bg-white px-4 py-3 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
                >
                  <svg
                    className="fill-jacarta-700 dark:fill-accent"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17.064,4.656l-2.05-2.035C14.936,2.544,14.831,2.5,14.721,2.5H3.854c-0.229,0-0.417,0.188-0.417,0.417v14.167c0,0.229,0.188,0.417,0.417,0.417h12.917c0.229,0,0.416-0.188,0.416-0.417V4.952C17.188,4.84,17.144,4.733,17.064,4.656M6.354,3.333h7.917V10H6.354V3.333z M16.354,16.667H4.271V3.333h1.25v7.083c0,0.229,0.188,0.417,0.417,0.417h8.75c0.229,0,0.416-0.188,0.416-0.417V3.886l1.25,1.239V16.667z M13.402,4.688v3.958c0,0.229-0.186,0.417-0.417,0.417c-0.229,0-0.417-0.188-0.417-0.417V4.688c0-0.229,0.188-0.417,0.417-0.417C13.217,4.271,13.402,4.458,13.402,4.688"></path>
                  </svg>
                  <span className="text-2xs ml-2 font-medium">
                    Full Scanned Manifest
                  </span>
                </button></Link>
              </div>
            )}

            {/* </div> */}
            <div className="container">
              <div className="grid grid-cols-3 gap-2 md:grid-cols-2 md:gap-[1rem] lg:grid-cols-5">
                {truckloadItems &&
                  truckloadItems
                    .slice((pageNumber - 1) * 20, pageNumber * 20)
                    .map((item) => {
                      const { title, _id, price, rating, quantity, images } =
                        item;

                      return (
                        <div
                          className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-3 px-5 transition-shadow hover:shadow-lg dark:border-transparent max-w-screen"
                          key={_id}
                        >
                          <div
                            className="flex flex-col"
                            style={{ minWidth: "4rem", maxWidth: "4rem" }}
                          >
                            <figure className="shrink-0 mr-1">
                              {/* <Link href={"/collection/"}> */}
                              <div className="relative block">
                                {/* <img src={image} alt={title} className="rounded-2lg" /> */}
                                <Image
                                  height={50}
                                  width={50}
                                  src={
                                    images[0]?.url
                                      ? `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${images[0]?.url}`
                                      : `/images/no_product.png`
                                  }
                                  alt={title}
                                  className="rounded-2lg"
                                  objectFit="cover"
                                />
                                <div className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                                  {/* {title?.slice(0, 15) || modelNumber}... */}
                                  {quantity}
                                </div>
                              </div>
                              {/* </Link> */}
                            </figure>
                            {rating && (
                              <div className="flex flex-row justify-start align-center">
                                <MdStar style={{ color: "yellow" }} />{" "}
                                <span className="dark:text-jacarta-300 text-sm">
                                  {Number(rating)?.toFixed(1) || 0}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="grow">
                            {/* <Link href={"/collection/"}> */}
                            <div className="block">
                              <span className="font-display text-sm text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                                {title.length > 18
                                  ? `${title.substring(0, 18)}...`
                                  : title}
                              </span>
                            </div>
                            {/* </Link> */}
                            <span className="dark:text-jacarta-300 text-sm font-bold">
                              $
                              {(price &&
                                (Number(price) / Number(quantity))?.toFixed(
                                  2
                                )) ||
                                0}
                            </span>
                            <div className="flex justify-between mt-2">
                              <div>
                                <DetailsModal product={item} />
                              </div>
                              <div>
                                <span className="text-accent font-display text-sm font-semibold">
                                  =$
                                  {Number(price)?.toFixed(1)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </section>
        </div>
        <div className="flex flex-col my-2 items-center">
          <Pagination
            className="dark:text-white dark:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
            count={Math.ceil(Number(truckloadItems?.length || 0) / 20)}
            page={pageNumber}
            variant="outlined"
            color="secondary"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default OfferTab;
