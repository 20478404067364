import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OfferTab from "./OfferTab";
import ManifestTab from "./ManifestTab";
import { tranding_category_filter } from "../../data/categories_data";
import "react-tabs/style/react-tabs.css";
import { useSelector } from "react-redux";
import Link from "next/link";

const ItemsTabs = ({
  truckloadItems,
  merchantProfile,
  stats,
  manifest_url,
}) => {
  const [tabsActive, setTabsActive] = useState(1);
  const [filterVal, setFilterVal] = useState(0);
  const [productsFilter, setProductsFilter] = useState("all");
  const [showContactInfos, setShowContactInfos] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user.isLoggedIn) {
      setShowContactInfos(true);
    } else setShowContactInfos(false);
  }, [user.isLoggedIn]);

  const fliteredProducts =
    productsFilter.toLowerCase() === "high to low"
      ? truckloadItems?.sort(
          (a, b) =>
            Number(b.price) / Number(b.quantity) -
            Number(a.price) / Number(a.quantity)
        )
      : productsFilter.toLowerCase() === "reviews"
      ? truckloadItems?.sort((a, b) => Number(b.rating) - Number(a.rating))
      : truckloadItems;

  const tabsHeadText = [
    {
      id: 1,
      text: "Products",
      icon: "offers",
    },
    {
      id: 2,
      text: "Manifest",
      icon: "manifest",
    },
    // {
    //   id: 3,
    //   text: "properties",
    //   icon: "properties",
    // },
    {
      id: 3,
      text: "details",
      icon: "details",
    },
    // {
    //   id: 4,
    //   text: "activities",
    //   icon: "activity",
    // },
    // {
    //   id: 5,
    //   text: "price history",
    //   icon: "price",
    // },
  ];
  // console.log(merchantProfile);
  return (
    <>
      <div className="scrollbar-custom mt-14 overflow-x-auto rounded-lg">
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-max md:min-w-fit tabs">
          <TabList className="nav nav-tabs flex items-center">
            {/* <!-- Offers --> */}
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item bg-transparent" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                        : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <svg className="icon mr-1 h-5 w-5 fill-current">
                      <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                    </svg>
                    <span className="font-display text-base font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>

          <TabPanel className="tab-content">
            {/* ProductsFilter */}
            <div className="mb-2 flex flex-wrap items-center justify-between mt-2">
              <ul className="flex flex-wrap items-center">
                {tranding_category_filter.map(({ id, svg, text }) => {
                  if (text === "all") {
                    return (
                      <li className="my-1 mr-2.5" key={id}>
                        <button
                          className={
                            filterVal === id
                              ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                              : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                          }
                          onClick={() => {
                            setProductsFilter(text);
                            setFilterVal(id);
                          }}
                        >
                          {text}
                        </button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="my-1 mr-2.5" key={id}>
                        <button
                          onClick={() => {
                            setProductsFilter(text);
                            setFilterVal(id);
                          }}
                        >
                          <div
                            className={
                              filterVal === id
                                ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                                : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                            }
                          >
                            {/* <svg
                              className={
                                filterVal === id
                                  ? "icon mr-1 h-4 w-4 transition-colors fill-white"
                                  : "icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
                              }
                            >
                              <use xlinkHref={`/icons.svg#icon-${svg}`}></use>
                            </svg> */}
                            <span>{text}</span>
                          </div>
                        </button>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            {stats?.foundCount > 0 && (
              <div className="mb-4 flex flex-wrap items-center justify-between mt-2">
                Showing {stats?.foundCount || 0} out of {stats?.total || 0}{" "}
                manifest products
              </div>
            )}
            <OfferTab
              truckloadItems={fliteredProducts}
              merchantProfile={merchantProfile}
              manifest_url={manifest_url}
            />
          </TabPanel>
          <TabPanel className="tab-content">
            <ManifestTab
              truckloadItems={truckloadItems}
              merchantProfile={merchantProfile}
              manifest_url={manifest_url}
            />
          </TabPanel>
          {/* <TabPanel>
            <Properties merchantProfile={merchantProfile} stats={stats} />
          </TabPanel> */}
          <TabPanel>
            {/* <!-- Details --> */}
            <div
              className="tab-pane fade"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
                {/* <div className="flex items-center justify-center">
                  <button
                    className="mt-2 dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 font-display text-jacarta-700 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
                    onClick={() => setShowContactInfos(!showContactInfos)}
                  >
                    {showContactInfos ? "Hide" : "Reveal Seller's Contact Info"}
                  </button>
                </div> */}
                {showContactInfos ? (
                  <>
                    <div className="mb-2 flex items-center">
                      <span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                        Owner Name:
                      </span>
                      <a
                        href={
                          merchantProfile?.legal_name
                            ? `/merchant/${merchantProfile?.legal_name}`
                            : "/#"
                        }
                        className="text-accent"
                      >
                        {merchantProfile?.name
                          ? merchantProfile.name
                          : "Not provided"}
                        {/* {console.log(merchantProfile)} */}
                      </a>
                    </div>
                    <div className="mb-2 flex items-center">
                      <span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                        Business Name:
                      </span>
                      <span className="text-jacarta-700 dark:text-white">
                        {merchantProfile?.legal_name
                          ? merchantProfile?.legal_name
                          : "Not provided"}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center">
                      <span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                        Email:
                      </span>
                      <a href="#" className="text-accent">
                        {merchantProfile?.email?.length &&
                        merchantProfile?.email?.length > 0
                          ? merchantProfile?.email
                          : "Not provided"}
                      </a>
                    </div>
                    <div className="mb-2 flex items-center">
                      <span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                        Address:
                      </span>
                      <span
                        className="js-copy-clipboard text-jacarta-700 cursor-pointer select-none dark:text-white"
                        data-tippy-content="Copy"
                      >
                        {merchantProfile?.address &&
                        merchantProfile?.state &&
                        merchantProfile?.zip
                          ? merchantProfile?.address +
                            ", " +
                            merchantProfile?.state +
                            " ," +
                            merchantProfile?.zip
                          : "Not provided"}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center">
                    {" "}
                    <p>Please sign in to see seller's contact info</p>
                    <Link href={"/login"}>
                      <button className=" font-display text-accent hover:underline">
                        Sign in
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ItemsTabs;
