const newseLatterData = [
	{
		id: '1',
		icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: 'icon-wallet' },

		title: ['Check current inventory'],
		text: "ui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius mod.",
	},
	{
		id: '2',
		icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-wallet' },

		title: ['Choose the right truckload'],
		text: 'ui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius mod.',
	},
	{
		id: '3',
		icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-gallery' },
		title: ['Request shipping quote'],
		text: 'ui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius mod.',
	},
	{
		id: '4',
		icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-list' },
		title: ['Complete transaction with seller'],
		text: 'ui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius mod.',
	},
];

export { newseLatterData };
