import React, { useState } from "react";
import HeadLine from "../headLine";
import { newseLatterData } from "../../data/newseLatterData";
import Image from "next/image";
import Tippy from "@tippyjs/react";
import { useForm } from "react-hook-form";

const NewseLatter = ({ bgWhite = true, isKlaviyoConnected }) => {
  const [status, setStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const [sortActive, setsortActive] = useState(1);
  const [newsletterFilter, setNewsletterFilter] = useState("all");
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
  
      let url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/gatsby/sendgridList`;

      const method = "post";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_GATSBY_API_TOKEN}`,
      };

      fetch(url, {
        method,
        headers,
        body: JSON.stringify({ email: data.email,name:data.name, vendor: newsletterFilter }),
      })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((res) => {
          setStatus("success");
          setShowModal(true);
          reset({
            name: "",
            email: "",
            vendor: "all",
          });
        })
        .catch((err) => {
          setStatus("failed");
          console.log(err);
        });
   
  };
  const sortText = [
    {
      id: 1,
      text: "All",
    },
    {
      id: 2,
      text: "Home Depot",
    },
    {
      id: 3,
      text: "Target",
    },
    {
      id: 4,
      text: "Walmart",
    },
    {
      id: 5,
      text: "Lowes",
    },
    {
      id: 6,
      text: "DG",
    },
  ];
  return (
    <section className="dark:bg-jacarta-800 relative py-18">
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full"
            layout="fill"
          />
        </picture>
      )}

      <div className="container py-4 md:pt-5">
        <HeadLine
          text="How to order/list"
          classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
        />

        <div className="grid grid-cols-2 gap-4 md:gap-12 md:grid-cols-2 lg:grid-cols-4">
          {newseLatterData.map((item) => {
            const { id, icon, title, text } = item;
            return (
              <div className="text-center newseLatter-item" key={id}>
                <div
                  className={`mb-6 inline-flex rounded-full p-3`}
                  style={{ backgroundColor: icon.parentBg }}
                >
                  <div
                    className={`inline-flex h-12 w-12 items-center justify-center rounded-full`}
                    style={{ backgroundColor: icon.childBg }}
                  >
                    <svg className="icon icon-wallet h-5 w-5 fill-white">
                      <use xlinkHref={`/icons.svg#${icon.svg}`}></use>
                    </svg>
                  </div>
                </div>
                <h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
                  {id}. {title}
                </h3>
                {/* <p className="dark:text-jacarta-300">{text}</p> */}
              </div>
            );
          })}
        </div>

        <p className="text-jacarta-700 mx-auto mt-20 max-w-2xl text-center text-lg dark:text-white">
          Join our mailing list to receive timely updates from your favorite
          truckloads.
        </p>

        <div className="mx-auto md:mx-60 mt-7">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {" "}
            <div className="mb-2">
              <label
                htmlFor="cname"
                className="font-display text-jacarta-700 text-sm dark:text-white ml-1"
              >
                Full Name<span className="text-red">*</span>
              </label>
              <input
                type="text"
                id="cname"
                {...register("name", {
                  required: { value: true, message: "Fullname required" },
                  minLength: {
                    value: 2,
                    message: "Name should ne be less than 2 charachters",
                  },
                  maxLength: {
                    value: 30,
                    message: "name should not be longer than 30 charachter",
                  },
                })}
                className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-2 px-4 dark:text-white dark:placeholder-white"
                placeholder=""
              />
              {errors.name && (
                <p className="text-red">{errors.name?.message}</p>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="item-name"
                className="font-display text-jacarta-700 text-sm dark:text-white ml-1"
              >
                Email<span className="text-red">*</span>
              </label>
              <input
                type="text"
                id="item-name"
                {...register("email", {
                  required: { value: true, message: "Email required" },
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Please provide a valid email",
                  },
                })}
                className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-2 px-4 dark:text-white dark:placeholder-white"
                placeholder=""
              />
              {errors.email && (
                <p className="text-red">{errors.email?.message}</p>
              )}
            </div>
            {/* <div className="mb-3">
              {" "}
              <span className="font-display text-jacarta-700 text-sm dark:text-white ml-1">
                Which vendor do you like to get updates from?
              </span>
              <div className="dropdown relative my-1 cursor-pointer">
                <Tippy
                  animation="fade"
                  arrow={false}
                  trigger="click"
                  interactive="true"
                  placement="bottom"
                  className="tooltip-container"
                  content={
                    <div
                      className="dropdown-menu dark:bg-jacarta-800 z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-jacarta-500"
                      aria-labelledby="categoriesSort"
                    >
                      {sortText.map((item) => {
                        const { id, text } = item;
                        return (
                          <button
                            key={id}
                            className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                            onClick={() => {
                              setsortActive(id);
                              setNewsletterFilter(text);
                            }}
                          >
                            {text}
                            {sortActive === id && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="fill-accent mb-[3px] h-4 w-4"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                              </svg>
                            )}
                          </button>
                        );
                      })}
                    </div>
                  }
                >
                  <div className="dark:bg-jacarta-700 mb-2 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-64 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white">
                    <span className="font-display text-jacarta-700 dark:text-accent">
                      Select vendor
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                    </svg>
                  </div>
                </Tippy>
              </div>
            </div> */}
          </div>

          <div className="flex items-center justify-center pt-5 space-x-4">
            <button
              onClick={handleSubmit(onSubmit)}
              className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className={showModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 overflow-y-auto">
              {status === "success" ? (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className="dark:border-jacarta-600 bg-green flex h-10 w-10 items-center justify-center rounded-full border-2 border-white"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </div>
                  <p className="text-jacarta-700 text-lg font-display px-8 py-5 dark:text-accent">
                    Thanks for subscribing!
                  </p>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <p className="text-jacarta-700 text-lg font-display dark:text-accent px-8 py-5">
                    Something went wrong!
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewseLatter;
