import React, { useEffect } from "react";
import { useState } from "react";
import { usePapaParse } from "react-papaparse";

const OfferTab = ({ truckloadItems, manifest_url }) => {
  const [header, setHeader] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { readRemoteFile } = usePapaParse();
  useEffect(() => {

    if (rows.length <= 0) {
      setIsLoading(true);
      setIsSuccess(false);
      readRemoteFile(
        process.env.NEXT_PUBLIC_ASSETS_BASE_URL + "/" + manifest_url,
        {
          complete: (results) => {
            console.log("---------------------------");
            console.log("Results:", results.data.length);
            setHeader(results.data[0]);
            setRows(results.data.slice(1));
            setIsLoading(false);
            setIsSuccess(true);
            console.log("---------------------------");
          },
        }
      );
    }
  });
  //   const regex = /,(?=[^\"\r\n]*\"(?:[^\"\r\n]*\"[^\"\r\n]*\")*[^\"\r\n]*$)/;
  //   const {
  //     isSuccess,
  //     data: truckload,
  //     isLoading,
  //     isError,
  //   } = useQuery(
  //     ["getManifestCSV", manifest_url],
  //     () => fetchManifest(manifest_url),
  //     {
  //       onSuccess: (data) => {
  //         data?.data?.text().then((txt) => {
  //           let rawCsv = txt.split("\n");
  //           const removedigit=/(?<=\d),(?=\d)/
  //          rawCsv.map(e=>{
  //           // let final=e.replace(removedigit,'')
  //           // console.log(final)
  //           // const result = final.replace(regex, '');
  //           // console.log("after replace",result)
  //           const regex = new RegExp(",(?=[^\\\"\\r\\n]*\\\"(?:[^\\\"\\r\\n]*\\\"[^\\\"\\r\\n]*\\\")*[^\\\"\\r\\n]*$)", 'gsi')
  // const str = `" DIRE,CT"" DR,IVE 3,200 CFM"
  // "50-PINT DEHUMIDIFIER, ENERGY STAR"
  // bnmbmn",ghjjh"
  // """T&G BRAIDED CABLE USB-C-USB-C, 6FT"""`;
  // let m;

  // while ((m = regex.exec(str)) !== null) {
  //     // This is necessary to avoid infinite loops with zero-width matches
  //     if (m.index === regex.lastIndex) {
  //         regex.lastIndex++;
  //     }

  //     // The result can be accessed through the `m`-variable.
  //     m.forEach((match, groupIndex) => {
  //         console.log(`Found match, group ${groupIndex}: ${match}`);
  //     });
  // }
  //           return result;
  //          })
  //           // console.log(rawCsv, typeof rawCsv);
  //           setHeader(rawCsv[0].split(","));
  //           setRows(rawCsv.slice(1).map((r) => r.split(",")));
  //         });
  //       },
  //     }
  //   );

  const downloadHandle = () => {
    window.location =
      process.env.NEXT_PUBLIC_ASSETS_BASE_URL + "/" + manifest_url;
  };

  return (
    <>
      {/* <!-- Offers --> */}
      <div
        className="tab-pane fade show active"
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base border border-b-0 px-4 pt-5 pb-2.5">
          <div className="flex flex-wrap">
            <button
              onClick={downloadHandle}
              className="dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border bg-white px-4 py-3 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 mr-2 h-4 w-4 group-hover:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M16.05 12.05L21 17l-4.95 4.95-1.414-1.414 2.536-2.537L4 18v-2h13.172l-2.536-2.536 1.414-1.414zm-8.1-10l1.414 1.414L6.828 6 20 6v2H6.828l2.536 2.536L7.95 11.95 3 7l4.95-4.95z"></path>
              </svg>
              <span className="text-2xs font-medium">Download Manifest</span>
            </button>
          </div>
        </div>
        {!isLoading && isSuccess && (
          <div
            role="table"
            className={`scrollbar-custom dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 grid max-h-72 w-full grid-cols-${6} overflow-y-auto rounded-lg rounded-tl-none border bg-white text-sm dark:text-white`}
          >
            <div className="contents" role="row">
              {header &&
                header?.slice(0, 6)?.map((row) => (
                  <div
                    className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
                    role="columnheader"
                    key={row[0]}
                  >
                    <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                      {row}
                    </span>
                  </div>
                ))}
              {/* <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                QTY
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Category
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Price
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Rating
              </span>
            </div> */}
            </div>
            {rows &&
              rows?.map((row) => {
                // const {
                //   title,
                //   _id,
                //   description="",
                //   listingURL="#",
                //   brand,
                //   price,
                //   rating,
                //   modelNumber,
                //   quantity,
                //   category,
                //   condition,
                // } = item;
                return (
                  <div className="contents" role="row" key={row[0]}>
                    {Object.keys(row)
                      .slice(0, 6)
                      .map((key) => (
                        <div
                          className={`dark:border-jacarta-600 border-jacarta-100  font-medium ${
                            key == 0
                              ? "text-green"
                              : key == 3
                              ? "text-accent"
                              : "text-dark"
                          } flex items-center whitespace-nowrap border-t py-4 px-4`}
                          role="cell"
                          key={row[0] + Math.random()}
                        >
                          {/* <span className="-ml-1" data-tippy-content="ETH">
										<svg className="icon mr-1 h-4 w-4">
											<use xlinkHref="/icons.svg#icon-ETH"></use>
										</svg>
									</span> */}
                          {row[key].length > 20
                            ? row[key].slice(0, 20) + ".."
                            : row[key]}
                        </div>
                      ))}
                    {/* <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  {quantity}
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                 {category} 
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                 {price}
                </div>
                <div
                  className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                   {rating}
                </div>
                 */}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default OfferTab;
