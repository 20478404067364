import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Feature_collections_data from "../../data/Feature_collections_data";
import Link from "next/link";

const Feature_collections_carousel = ({ truckloads }) => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        breakpoints={{
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {truckloads.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt)
        }).map((item) => {
          const { _id, images, title, slug, store,isSold } = item;

          return (
            <SwiperSlide key={_id}>
              <article>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                  <Link href={`/truckload/${slug || _id}`}>
                    <a className="flex space-x-[0.625rem]">
                      <figure className=" relative w-[74.5%] h-full">
                        <Image
                          src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}${
                            "/" + images[0]?.url || "/no_product.png"
                          }`}
                          alt="item 1"
                          className="rounded-[0.625rem]"
                          width={150}
                          height={240}
                          objectFit="cover"
                          layout="responsive"
                        />
                        {isSold && (
                          <div className="absolute left-3 -bottom-1">
                            <img src="/images/sold.svg" alt="sold" />
                          </div>
                        )}
                        <div
                          className={
                            store?.toLowerCase() === "hd"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-orange-600 p-2"
                              : store?.toLowerCase() === "tgt"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                              : store?.toLowerCase() === "lws"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-blue p-2"
                              : store?.toLowerCase() === "amz"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-yellow p-2"
                              : store?.toLowerCase() === "dg"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-yellow p-2"
                              : store?.toLowerCase() === "kl"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                              : store?.toLowerCase() === "wm"
                              ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-blue p-2"
                              : "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                          }
                        >
                          <span
                            className={
                              store?.toLowerCase() === "wm"
                                ? "text-yellow text-sm"
                                : store?.toLowerCase() === "amz" ||
                                  store?.toLowerCase() === "dg"
                                ? "text-black text-sm"
                                : "text-white text-sm"
                            }
                          >
                            {store}
                          </span>
                        </div>
                      </figure>
                      <span className="flex w-1/3 flex-col space-y-[0.625rem]">
                        {item.truckloadItems &&
                          item.truckloadItems
                            .slice(0, 3)
                            .map((prod, index) =>
                              prod.images.length > 0 ? (
                                <img
                                  key={"images-" + index}
                                  src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${prod.images[0].url}`}
                                  alt="item 1"
                                  className="h-full rounded-[0.625rem] object-cover"
                                  loading="lazy"
                                />
                              ) : (
                                <img
                                  key={"images-" + index}
                                  src={"/images/no_product.png"}
                                  alt="item 1"
                                  className="h-full rounded-[0.625rem] object-cover"
                                  loading="lazy"
                                />
                              )
                            )}
                        {/* <img
													src={subImage1}
													alt="item 1"
													className="h-full rounded-[0.625rem] object-cover"
													loading="lazy"
												/>

												<img
													src={subImage2}
													alt="item 1"
													className="h-full rounded-[0.625rem] object-cover"
													loading="lazy"
												/>
												<img
													src={subImage3}
													alt="item 1"
													className="h-full rounded-[0.625rem] object-cover"
													loading="lazy"
												/> */}
                      </span>
                    </a>
                  </Link>

                  <Link href={`/truckload/${slug || _id}`}>
                    <a className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white">
                      {title}
                    </a>
                  </Link>

                  <div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                    <div className="flex flex-wrap items-center">
                      <Link href={`/truckload/item_${slug || _id}`}>
                        <a className="mr-2 shrink-0">
                          <img
                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${item.merchantProfile?.thumbnail}`}
                            alt="owner"
                            className="h-5 w-5 rounded-full"
                          />
                        </a>
                      </Link>
                      <span className="dark:text-jacarta-400 mr-1">by</span>
                      <Link href={`/truckload/${slug || _id}`}>
                        <a className="text-accent">
                          <span>{item.merchantProfile?.name}</span>
                        </a>
                      </Link>
                    </div>
                    {/* <span className="dark:text-jacarta-300 text-sm">
                      {itemsCount} Items
                    </span> */}
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default Feature_collections_carousel;
