import React from 'react';
import HeadLine from '../headLine';
import Feature_collections_carousel from '../carousel/Feature_truckload_carousel';
import Image from 'next/image';

const Feature_collections = ({ bgWhite = false, truckloads }) => {
	return (
	
			<section className="pt-18 pb-20 md:pt-0 md:pb-8 relative">
				{bgWhite && (
					<picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
						<Image
							src="/images/gradient_light.jpg"
							alt="gradient"
							className="h-full"
							layout="fill"
						/>
					</picture>
				)}
				<div className="container">
					<HeadLine
						image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f4a5.png"
						text="New Truckloads"
						classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
					/>
					<div className="relative">
						<Feature_collections_carousel truckloads={truckloads} />
					</div>
				</div>
			</section>
		
	);
};

export default Feature_collections;
