import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import Link from "next/link";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const BidsCarousel = ({ Truckloads }) => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={50}
        slidesPerView="auto"
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".truckloads-swiper-button-next",
          prevEl: ".truckloads-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {Truckloads?.map((item, index) => {
          const {
            name,
            images,
            price,
            merchantProfile,
            _id,
            slug,
            store,
            isSold,
          } = item;

          return (
            <SwiperSlide className="text-white" key={index}>
              <article>
                <div className=" cursor-pointer dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                  <figure className="relative">
                    {/* {`item/${itemLink}`} */}
                    <Link href={"/truckload/" + (slug || _id)}>
                      {/* <div className="w-full"> */}
                      <Image
                        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${
                          images[0]?.url ? images[0]?.url : "no_product.png"
                        }`}
                        alt={name}
                        height={"100%"}
                        width={"100%"}
                        layout="responsive"
                        objectFit="cover"
                        className="rounded-[0.625rem] object-cover w-46"
                        loading="lazy"
                      />
                      {/* </div> */}
                    </Link>
                    {isSold && (
                      <div className="absolute left-3 -bottom-1">
                        <img src="/images/sold.svg" alt="sold" />
                      </div>
                    )}
                    <div
                      className={
                        store?.toLowerCase() === "hd"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-orange-600 p-2"
                          : store?.toLowerCase() === "tgt"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                          : store?.toLowerCase() === "lws"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-blue p-2"
                          : store?.toLowerCase() === "amz"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-yellow p-2"
                          : store?.toLowerCase() === "dg"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-yellow p-2"
                          : store?.toLowerCase() === "kl"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                          : store?.toLowerCase() === "wm"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-blue p-2"
                          : "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                      }
                    >
                      <span
                        className={
                          store?.toLowerCase() === "wm"
                            ? "text-yellow text-sm"
                            : store?.toLowerCase() === "amz" ||
                              store?.toLowerCase() === "dg"
                            ? "text-black text-sm"
                            : "text-white text-sm"
                        }
                      >
                        {store}
                      </span>
                    </div>{" "}
                  </figure>
                  <div className="mt-4 flex items-center justify-between">
                    <Link href={"/truckload/" + (slug || _id)}>
                      <a>
                        <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                          {name}
                        </span>
                      </a>
                    </Link>
                    <span className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md border py-1 px-2">
                      {/* <Tippy content={<span>ETH</span>}>
												<img src="/images/eth-icon.svg" alt="" className="w-3 h-3 mr-1" />
											</Tippy> */}

                      <span className="text-green text-sm font-medium tracking-tight">
                        ${price}
                      </span>
                    </span>
                  </div>
                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-300 text-jacarta-500">
                      Seller: {merchantProfile?.name}
                    </span>
                  </div>

                  <div className="mt-8 flex items-center justify-between">
                    <Link href={"/truckload/" + (slug || _id)}>
                      <button
                        type="button"
                        className="text-accent font-display text-sm font-semibold"
                        // onClick={() => dispatch(bidsModalShow())}
                      >
                        See details
                      </button>
                    </Link>
                    {/* <Likes like={react_number} classes="flex items-center space-x-1" /> */}
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group truckloads-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group truckloads-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
